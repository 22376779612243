import React from 'react'

import ImgX from "../assets/about.jpeg"
import Produk1 from "../assets/produk1.jpg"
import Produk2 from "../assets/produk2.jpg"
import Produk3 from "../assets/produk3.jpg"

export default function Home() {
  return (
    <div>
  <body class="bg-gray-100">
    
    <nav class="bg-blue-900 p-4">
        <div class="container mx-auto flex justify-between items-center">
            <a href="/" class="text-white text-2xl font-semibold">FootGood</a>
            <ul class="flex space-x-6 text-white">
                <li><a href="#about" class="hover:underline">About</a></li>
                <li><a href="#products" class="hover:underline">Products</a></li>
                <li><a href="#contact" class="hover:underline">Contact</a></li>
            </ul>
        </div>
    </nav>

    
    <header class="bg-blue-900 text-white py-16 px-4">
        <div class="container mx-auto text-center">
            <h1 class="text-4xl font-semibold mb-4">Discover the Perfect Pair</h1>
            <p class="text-lg">Shop the latest trends in footwear at FootGood</p>
            <a href="#products" class="bg-yellow-500 text-blue-900 hover:bg-yellow-400 py-2 px-6 mt-8 rounded-full inline-block transition duration-300">Explore Now</a>
        </div>
    </header>


    <section id="about" class="py-16">
        <div class="container mx-auto flex items-center">
            <div class="w-1/2">
                <img src={ImgX} alt="About FootGood" class="rounded-full shadow-lg"/>
            </div>
            <div class="w-1/2 px-8">
                <h2 class="text-3xl font-semibold mb-4">About Us</h2>
                <p class="text-gray-700">FootGood is your go-to destination for high-quality footwear. We offer a wide range of styles and brands to suit your every need. Our mission is to provide the best shopping experience for shoe enthusiasts.</p>
            </div>
        </div>
    </section>

   
    <section id="products" class="bg-gray-100 py-16">
        <div class="container mx-auto text-center">
            <h2 class="text-3xl font-semibold mb-8">Our Products</h2>
         
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <img src={Produk1} alt="Product 1" class="mb-4"/>
                    <h3 class="text-xl font-semibold mb-2">Men's Running Shoes</h3>
                    <p class="text-gray-700">$89.99</p>
                </div>
              
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <img src={Produk2} alt="Product 2" class="mb-4"/>
                    <h3 class="text-xl font-semibold mb-2">Women's Sneakers</h3>
                    <p class="text-gray-700">$79.99</p>
                </div>
           
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <img src={Produk3} alt="Product 3" class="mb-4"/>
                    <h3 class="text-xl font-semibold mb-2">Kid's Athletic Shoes</h3>
                    <p class="text-gray-700">$59.99</p>
                </div>
            </div>
        </div>
    </section>

   
    <section id="contact" class="bg-blue-900 text-white py-16">
        <div class="container mx-auto text-center">
            <h2 class="text-3xl font-semibold mb-8">Contact Us</h2>
            <p class="text-lg">Questions or feedback? Get in touch with our team.</p>
            <a href="mailto:contact@footgood.com" class="bg-yellow-500 text-blue-900 hover:bg-yellow-400 py-2 px-6 mt-8 rounded-full inline-block transition duration-300">Email Us</a>
        </div>
    </section>

   
    <footer class="bg-gray-900 text-white py-8">
        <div class="container mx-auto text-center">
            <p>&copy; 2023 FootGood. All rights reserved.</p>
        </div>
    </footer>
</body>
    </div>
  )
}
